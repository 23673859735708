<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader },
  page: {
    title: "Instructions",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Instructions",
      items: [
        {
          text: "Londex",
        },
        {
          text: "Instructions",
          active: true,
        },
      ],
      prizes: [
        '0,1x',
        '0,25x',
        '0,5x',
        '1x',
        '2x',
        '3x',
        '4x',
        '5x',
        '10x',
        'Jackpot',
      ],
    };
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="card text-center">
            <div class="card-body">
              <br/>
              <div class="clearfix"></div>
              <div class="mb-4">
                <img
                  src="https://i.pinimg.com/564x/e9/d4/97/e9d49723d00cbb642dd0817db861af84.jpg"
                  height="80"
                />
              </div>
              <h5 class="font-size-16 mb-1">
                <a href="#" class="text-dark">Buy Ticket</a>
              </h5>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="card text-center">
            <div class="card-body">
              <br/>
              <div class="clearfix"></div>
              <div class="mb-4">
                <img
                  :src="require('@/assets/wheel.png')"
                  height="80"
                />
              </div>
              <h5 class="font-size-16 mb-1">
                <a href="#" class="text-dark">Click on the Wheel</a>
              </h5>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="card text-center">
            <div class="card-body">
              <br/>
              <div class="clearfix"></div>
              <div class="mb-4">
                <img
                  :src="require('@/assets/trophy.png')"
                  height="80"
                />
              </div>
              <h5 class="font-size-16 mb-1">
                <a href="#" class="text-dark">Win Prizes</a>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>

    <PageHeader title="Prizes" :items="[]" />
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-4" v-for="(prize, index) in prizes" :key="index">
          <div class="card text-center">
            <div class="card-body">
              <br/>
              <div class="clearfix"></div>
              <div class="mb-4">
                <img
                  :src="require('@/assets/trophy.png')"
                  height="80"
                />
              </div>
              <p class="font-size-16 mb-1">
                <a href="#" class="text-dark">{{ prize }}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
